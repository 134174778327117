<template>
  <!-- Start Slider Area  -->
  <div class="rn-slider-area demo_slider-area">
    <!-- Start Single Slide  -->
    <div class="slide slide-style-1 slider-fixed--height d-flex align-center bg_image bg_image--1" data-black-overlay="8">
      <v-container class="position-relative">
        <v-row>
          <v-col cols="12">
            <div class="inner">
              <h1 class="heading-title theme-gradient">
                210Day AI Driven<br />导航知识库社区
              </h1>
            </div>
          </v-col>
        </v-row>
        <!-- End .row -->
        <div class="text-center section-title">
          <p class="description"> 发掘无限，智慧分享<br>欢迎来到独特的导航知识库社区，一个专为求知者设计的数字平台。<br>在这里，您的浏览器将成为最个性化的知识宝库，每次打开新标签都是一次思维的探索。
          </p>
        </div>

        <!-- Start Service Area  -->
        <div class="service-wrapper service-white mt--20">
          <div class="service-wrapper service-white">
            <v-row class="row--25">
              <!-- Start Single Service  -->
              <v-col xl="3" lg="3" md="3" sm="6" cols="12" class="mt--10">
                <div class="text-center ">
                  <a class="btn-default size-md w-100" href="#button"><i class="mr--5" v-html="iconSvg('chrome')"></i>Chrome插件</a>
                </div>
              </v-col>
              <v-col xl="3" lg="3" md="3" sm="6" cols="12" class="mt--10">
                <div class="text-center">
                  <a class="btn-default size-md w-100" href="#button"><i class="mr--5" v-html="iconSvg('chrome')"></i>Edge插件</a>
                </div>
              </v-col>
              <v-col xl="3" lg="3" md="3" sm="6" cols="12" class="mt--10">
                <div class="text-center">
                  <a class="btn-default size-md w-100" href="#button"><i class="mr--5" v-html="iconSvg('chrome')"></i>网页版</a>
                </div>
              </v-col>
              <v-col xl="3" lg="3" md="3" sm="6" cols="12" class="mt--10">
                <div class="text-center">
                  <a class="btn-default size-md w-100" href="#button"><i class="mr--5" v-html="iconSvg('chrome')"></i>CRX插件下载</a>
                </div>
              </v-col>
              <!-- End Single Service  -->
            </v-row>
          </div>
        </div>

        <div class="text-center">
           <div class="mac-desktop mt-4">
            <img class="mac-desktop-image" src="../../assets/images/210day/imac-desktop.png">
           </div>
        </div>
        <!-- End Service Area  -->
      </v-container>
    </div>
    <!-- End Single Slide  -->
  </div>
  <!-- End Slider Area  -->
</template>

<script>
import feather from "feather-icons";
import ServiceOne from "../../components/service/ServiceOne";
export default {
  components: {
    ServiceOne,
  },
  methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg({'width': 12, 'height': 12});
      },
    },
};
</script>

<style lang="scss">
.demo_slider-area {
  .slide.slide-style-1 .inner {
    padding-top: 160px;
  }

  @media only screen and (max-width: 991px) {
    .slider-fixed--height {
      padding-bottom: 70px;
    }

    .service-white .service__style--1 .content p {
      padding: 0 30px;
    }
  }
}

.slider-wrapper {
  .demo_slider-area {
    .service-wrapper {
      margin-bottom: 0;
    }
  }
}

.mac-desktop {
  position: relative;
  top: 80px;
}

.mac-desktop-image {
  width: 500px;
}
</style>
