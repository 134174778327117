<template>
  <v-container>
    <v-row align="center" class="row--35">
      <v-col lg="5" md="5" cols="12">
        <div class="thumbnail">
          <img src="../../assets/images/210day/210day-description-black.jpeg"/>
        </div>
      </v-col>
      <v-col lg="7" md="7" cols="12">
        <div class="about-inner inner">
          <div class="section-title">
            <div class="icon" v-html="iconSvg(icon)"></div>
            <h2 class="heading-title">发掘无限，智慧分享</h2>
            <p class="description">
              追求简洁，构建个性化的数字导航。一个您可以随心布局、自选插件、跨设备同步的私人空间，正等待着您的巧手雕琢。
            </p>
            <p class="description">
              在知识的海洋中航行，我们每天都在学习，不断地汲取新鲜资讯。那些行业佼佼者的智慧宝库，他们日常关注的网站，都是我们成长的阶梯。每一天，我们都有机会超越昨天的自己，不断进步，无论是210天还是1024天。
            </p>
            <p class="description">
              告别那些找不到工具、忘记网站、书签混乱的日子。是时候打造一个属于您自己的知识管理系统，让每一次搜索都精准高效，让每一次学习都成为享受。这是一场向卓越迈进的旅程，简洁而美好。
            </p>
            <div class="purchase-btn">
              <router-link class="btn-transparent" to="https://i.210day.com"
                >210day导航</router-link
              >
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        icon: "send",
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
